import { openKkiapayWidget } from "kkiapay";
import {
  afficherErrorToast,
  afficherMessagesSuccess,
  afficherOneMessageError,
  getSirClass,
  initiateFedaPayCheckout,
  loadTresorPayScript,
} from "../utils/helpers";

export const KkiapayStrategy = {
  execute: ({ montant_payer, reference_dossier, auth, config }) => {
    //console.log("Kkiapay Config:", config);
    //console.log("Params:", { montant_payer, reference_dossier, auth });

    if (!config || !config.key) {
      throw new Error("Clé API Kkiapay manquante ou invalide !");
    }

    openKkiapayWidget({
      amount: montant_payer,
      position: "center",
      data: reference_dossier,
      name: auth?.user?.fullname,
      theme: "green",
      api_key: config.key,
      sandbox: config.isSandbox,
      email: auth?.user?.email,
      phone: parseInt(auth.user.telephone),
    });
  },
};

export const FedaPayStrategy = {
  execute: ({
    montant_payer,
    reference_dossier,
    auth,
    successHandlerFedaPay,
  }) => {
    initiateFedaPayCheckout({
      amount: montant_payer,
      description: reference_dossier,
      client: {
        firstname: auth?.user?.fullname,
        email: auth.user.email,
        phone_number: {
          number: parseInt(auth.user.telephone),
          country: "BJ",
        },
      },
      onCompleteCallback: (resp) => {
        if (resp.reason === "dialog_dismissed") {
          afficherErrorToast("Vous avez fermé la fenêtre de paiement");
        } else {
          afficherMessagesSuccess("Transaction terminée: " + resp.reason);
        }
        successHandlerFedaPay(resp.transaction);
      },
    });
  },
};

export const TresorPayStrategy = {
  execute: async ({
    montant_payer,
    reference_dossier,
    auth,
    successHandlerTresorPay,
  }) => {
    try {
      // Charger le script TresorPay
      await loadTresorPayScript();
      // Vérifier que TresorPay est chargé
      if (!window.TresorPay) {
        console.error("TresorPay n'est pas chargé.");
        return;
      }

      // Créer un bouton invisible dynamiquement
      let container = document.createElement("button");
      container.id = "tresorpay-btn";
      container.style.display = "none"; // Garder le bouton invisible
      document.body.appendChild(container);

      const publicKey =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_TRESORPAY_PUBLIC_KEY
          : process.env.REACT_APP_TRESORPAY_SANDBOX_PUBLIC_KEY;

      //Le montant autorisé en mode test est 100f
      const amount =
        process.env.NODE_ENV === "production" ? 1 : 1;

      const sir_class = getSirClass(parseInt(amount)) ;
      //console.log("amount", amount)
      //console.log("sir_class", sir_class)

      // Initialiser TresorPay
      window.TresorPay.init("#tresorpay-btn", {
        public_key: publicKey,
        transaction: {
          amount: amount, //montant à payer
          description: reference_dossier,
          custom_metadata: {
            // Ajouter les données personnalisées
            receipe_nature : "national",
            receipe_class : 1034,
            sir_class: sir_class,
            sir_caisse: "026X"
          }
        },
        customer: {
          email: auth?.user?.email, // Email du client
          lastname: auth?.user?.fullname, // Nom du client
          /*firstname: auth.user.prenoms,*/ // Prénom du client
          phone_number: {
            number: parseInt(auth?.user?.telephone),
            country: "BJ",
          },
        },
        currency: {
          iso: "XOF", // Devise utilisée
        },
        onComplete: (resp) => {
          if (resp.reason === "dialog_dismissed") {
            afficherErrorToast("Vous avez fermé la fenêtre de paiement");
          } else {
            afficherMessagesSuccess("Transaction terminée: " + resp.reason);
          }
          successHandlerTresorPay(resp.transaction);
          //successHandlerTresorPay(response["v1/payment_intent"])
        },
        onError: (error) => {
          console.error("Erreur de paiement :", error);
          afficherOneMessageError("Une erreur est survenue lors du paiement.");
        },
      });

      // Simuler un clic sur le bouton invisible
      container.click();

      // Nettoyer le DOM après le paiement
      setTimeout(() => {
        if (document.body.contains(container)) {
          document.body.removeChild(container);
        }
      }, 1000);
    } catch (error) {
      console.error("Erreur dans la stratégie TresorPay :", error);
      afficherErrorToast(error)
    }
  },
};
