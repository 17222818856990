import Swal from "sweetalert2";
import PaiementsService from "../services/PaiementsService";
import { afficherMessagesErreur, afficherOneMessageError } from "../utils/helpers";

import { myroutes } from "../routes/webroute";

export const PaiementDossierStore = ({dispatch, navigate, code_transaction, paiementsInfo, token, setDataIsVerified = null, setLoading, resetDossierFormulaire = null, isActeur = false}) => {
    //const dispatch = useDispatch();
    //const navigate = useNavigate();

    PaiementsService.store(code_transaction, paiementsInfo, token)
      .then((res) => {
        //console.log(res.data.data)
        setDataIsVerified(false);
        if (res.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Enrôlement réussi",
            text: res.data.data?.transaction?.message,
          });
          // Réinitialiser après la validation du paiement
          dispatch(resetDossierFormulaire());
          // Navigation sur la page de confirmation
          navigate(isActeur ? myroutes.dossiersacteurs_index : myroutes.dossiers_index);
        } else if (res.data.data.echec) {
          setLoading(false); // Fin du chargement en cas d'erreur de validation

          Swal.fire({
            icon: "warning",
            title: "Une erreur s'est produite !",
            text: res.data.data.echec,
          });
        } else if (res.data.erreur) {
          setLoading(false); // Fin du chargement en cas d'erreur de validation

          Swal.fire({
            icon: "error",
            title: "Erreur!",
            text: res.data.message,
          });
        }
      })
      .catch((error) => {
        setLoading(false); // Fin du chargement en cas d'erreur de validation
        afficherOneMessageError(error.response?.data?.message || 'Une erreur est survenue');
        afficherMessagesErreur(error.response?.data?.error);
        console.log("Error", error);
        setDataIsVerified(true);
      });
}